import {Link} from "gatsby"
import React from "react"

// CSS Modules
import sharedStyles from "../styles/shared.module.styl"

// Shared components
import logoImg from "../images/logos/letras_azul_coehlo.png"

import { FacebookIcon, InstagramIcon, TwitterIcon } from "./fa"

const FooterLink = ({ pageTitle, to = "/", out = false }) => (
  <li>
    {
      out ?
        <a href={to} target="_blank" rel="noopener noreferrer">
          {pageTitle}
        </a> :
        <Link to={to}>
          {pageTitle}
        </Link>
    }
  </li>
)


class Footer extends React.Component{
  render() {
    const {hideMenu} = this.props;

    return(
      <footer
        className={sharedStyles.footer}
      >
        <div className={sharedStyles.footer__container}>
          <div>
            <Link to="/" >
              <img src={logoImg}
                   className={sharedStyles.logo}
                   alt="Dharma Books + Publishing "/>
            </Link>
            <div className={sharedStyles.social}>
              <FacebookIcon to="https://www.facebook.com/DharmaBooks"/>
              <TwitterIcon to="https://twitter.com/Dharma_Books"/>
              <InstagramIcon to="https://www.instagram.com/dharma_books/"/>
            </div>
          </div>
          <div>
            {hideMenu ? <ul>
                Próximamente
              </ul>:
              <ul>
                <FooterLink pageTitle="Servicios Editoriales" to="/servicios_editoriales/"/>
                <FooterLink pageTitle="Contacto" to="/contacto/" />
                <FooterLink pageTitle="Tienda" to="/libros/" />
                <FooterLink pageTitle="Acerca De" to="/acerca/" />
                <span id="siteseal"><script async type="text/javascript" src="https://seal.godaddy.com/getSeal?sealID=SLMoJpGkHSDGrJ598GKcTR6zGYwFdeLtlvBM6NGMfrYy5dgGlH1nEL1awIAo"></script></span>
              </ul>
            }
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
