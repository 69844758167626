import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql} from "gatsby";
import {StickyContainer, Sticky} from 'react-sticky'

import Header from "./header"
import Footer from "./footer"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <StickyContainer>
      <Sticky>
        {({isSticky}) => (
          <Header
            isSticky={isSticky}
            siteTitle={data.site.siteMetadata.title} />
        )}
      </Sticky>
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0px 1.0875rem 1.45rem`,
          paddingTop: 0,
        }}
      >
        <main>{children}</main>
      </div>
      <Footer />
    </StickyContainer>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

export class MainLayout extends React.Component {
  render() {
    const {siteTitle, children, hideMenu} = this.props;

    return (
      <StickyContainer>
        <Sticky>
          {({style, isSticky, distanceFromTop}) => (
            <Header
              hideMenu={hideMenu}
              style={style}
              isSticky={isSticky}
              distanceFromTop={distanceFromTop}
              siteTitle={siteTitle} />
          )}
        </Sticky>
        <div>
          <main>{children}</main>
        </div>
        <Footer hideMenu={hideMenu} />
      </StickyContainer>
    );
  }
}
