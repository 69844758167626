import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus, faBars, faBook, faShoppingCart, faPencilRuler, faStore, faExternalLinkAlt, faPalette,
faDrum, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTwitter, faInstagram, faAmazon } from "@fortawesome/free-brands-svg-icons";
import { Link } from "gatsby";

import 'bootstrap/dist/css/bootstrap.min.css';
// CSS Modules
import sharedStyles from "../styles/shared.module.styl"
import bookStyles from "../styles/book.module.styl"


export const AddToCartIcon = () => (
  <div className={sharedStyles.icon}>
    <FontAwesomeIcon fixedWidth icon={faCartPlus} />
  </div>
);


export const BarsIcon = () => (
  <div className={sharedStyles.icon}>
    <FontAwesomeIcon fixedWidth icon={faBars} />
  </div>
);

export const PalleteIcon = () => (
  <div className={sharedStyles.icon}>
    <FontAwesomeIcon fixedWidth icon={faPalette} />
  </div>
);

export const StopwatchIcon = () => (
  <div className={sharedStyles.icon}>
    <FontAwesomeIcon fixedWidth icon={faDrum} />
  </div>
);

export const PencilRulerIcon = (isRound, center) => (
  <div className={`${sharedStyles.icon}
    ${center ? sharedStyles.roundIcon : ""}
    ${isRound ? sharedStyles.roundIconCenter : ""}`}
  >
    <FontAwesomeIcon fixedWidth icon={faPencilRuler} />
  </div>
);

export const StoreIcon = ({to}) => (
  <div className={sharedStyles.icon}>
    {
      to ?
        <a href={to} target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon className={sharedStyles.buyIcon} fixedWidth icon={faStore} />
        </a> :
        <OverlayTrigger
          placement="right"
          overlay={<Tooltip>Aún no está disponible.</Tooltip>}
        >
          <FontAwesomeIcon className={`${sharedStyles.buyIcon} ${sharedStyles.buyIconDisabled}`}
                           fixedWidth icon={faStore} />
        </OverlayTrigger>
    }
  </div>
);

export const ShopIcon = ({to}) => (
  <div className={sharedStyles.icon}>
    {
      to ?
        <a href={to} target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon className={sharedStyles.buyIcon} fixedWidth icon={faShoppingCart} />
        </a> :
        <OverlayTrigger
          placement="right"
          overlay={<Tooltip>Aún no está disponible.</Tooltip>}
        >
          <FontAwesomeIcon className={`${sharedStyles.buyIcon} ${sharedStyles.buyIconDisabled}`}
                           fixedWidth icon={faShoppingCart} />
        </OverlayTrigger>
    }
  </div>
);

export const ShopLink = ({icon, storeName, to}) => (
  <div className={bookStyles.detailBuyBtn}>
    {
      to ?
        <a href={to} target="_blank" rel="noopener noreferrer" className={sharedStyles.buyLink}>
          {icon} {storeName}
        </a> :
        <span className={sharedStyles.buyLinkDisabled}>
          {icon} {storeName}
        </span>
    }
  </div>
)

export const SimpleShopLink = ({icon, storeName, to}) => (
  <div className={bookStyles.detailBuyBtn}>
    {
      to ?
        <a href={to} target="_blank" rel="noopener noreferrer" className={sharedStyles.simpleBuyLink}>
          {icon} {storeName}
        </a> :
        <span className={sharedStyles.buyLinkDisabled}>
          {icon} {storeName}
        </span>
    }
  </div>
)

export const SimpleAmazonShopLink = ({to}) => (
  <div className={bookStyles.detailBuyBtn}>
    {
      to ?
        <a href={to} target="_blank" rel="noopener noreferrer" className={sharedStyles.simpleBuyLink}>
          <FontAwesomeIcon
            className={sharedStyles.simpleAmazonBuyIcon}
            fixedWidth icon={faAmazon} />
          Amazon
        </a> :
        <span className={sharedStyles.buyLinkDisabled}>
          <AmazonIcon to={to} /> Amazon
        </span>
    }
  </div>
)

export const AmazonIcon = ({to, iconClassName}) => (
  <div className={sharedStyles.icon}>
    {
      to ?
        <a href={to} target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon
            className={iconClassName ? iconClassName : sharedStyles.buyIcon} fixedWidth icon={faAmazon} />
        </a> :
        <OverlayTrigger
          placement="right"
          overlay={<Tooltip>Aún no está disponible.</Tooltip>}
        >
          <FontAwesomeIcon
            className={`${iconClassName ? iconClassName : sharedStyles.buyIcon} ${sharedStyles.buyIconDisabled}`}
            fixedWidth icon={faAmazon} />
        </OverlayTrigger>
    }
  </div>
)

export const BookIcon = ({to}) => (
  <div className={sharedStyles.icon}>
    <Link to={to}>
      <FontAwesomeIcon fixedWidth icon={faBook} />
    </Link>
  </div>
)

export const FacebookIcon = ({to}) => (
  <div className={sharedStyles.icon}>
    <a href={to} target="_blank" rel="noopener noreferrer">
      <FontAwesomeIcon fixedWidth icon={faFacebook} />
    </a>
  </div>
)

export const TwitterIcon = ({to}) => (
  <div className={sharedStyles.icon}>
    <a href={to} target="_blank" rel="noopener noreferrer">
      <FontAwesomeIcon fixedWidth icon={faTwitter} />
    </a>
  </div>
)

export const InstagramIcon = ({to}) => (
  <div className={sharedStyles.icon}>
    <a href={to} target="_blank" rel="noopener noreferrer">
      <FontAwesomeIcon fixedWidth icon={faInstagram} />
    </a>
  </div>
)

export const ExternalLinkIcon = () => (
  <div className={sharedStyles.inlineIcon}>
    <FontAwesomeIcon fixedWidth icon={faExternalLinkAlt} />
  </div>
)


export const BuyLoader = () => (
  <div className={sharedStyles.buyLoader}>
    <FontAwesomeIcon fixedWidth spin icon={faSpinner} />
  </div>
)
