import {Link} from "gatsby"
import PropTypes from "prop-types"
import React from "react"

// CSS Modules
import sharedStyles from "../styles/shared.module.styl"

// Shared components
import { BarsIcon, BookIcon, ShopIcon } from "./fa"

const NavLink = ({ pageTitle, to = "/", out }) => (
  <span>
    {
      out ?
        <a href={to} target="_blank" rel="noopener noreferrer">
          {pageTitle}
        </a> :
        <Link to={to}>
          {pageTitle}
        </Link>
    }
  </span>
)


class Header extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      showMobileNav: false
    };

    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu() {
    const {showMobileNav} = this.state;
    this.setState({ showMobileNav: !showMobileNav });
  }

  render() {
    const {style, distanceFromTop, hideMenu} = this.props; // Inherited from Sticky
    const {showMobileNav} = this.state;

    return(
      <header
        className={sharedStyles.header}
        style={style}
      >

        <div className={distanceFromTop ?
          sharedStyles.headerNotice__hidden : sharedStyles.headerNotice}>
          Envío gratis a todo México
        </div>
        <div className={sharedStyles.header__container} >
          <div className={`${sharedStyles.nav} ${distanceFromTop ?
            sharedStyles.navSticky : ""}`}>
            {hideMenu ? <div className={sharedStyles.center}>
                <NavLink pageTitle="na" to={"/"} />
              </div> :
              <React.Fragment>
                {/* Full nav,desktop*/}
                <div className={sharedStyles.left}>
                  <NavLink pageTitle="Libros" to="/libros/" />
                  <NavLink pageTitle="Autores" to="/autores/"/>
                  <NavLink pageTitle="Recepción de manuscritos" to="/recepcion_de_manuscritos/"/>
                  <NavLink pageTitle="Noticias" to="/noticias/" />
                </div>
                <div className={sharedStyles.center}>
                  <NavLink pageTitle="na" to={"/"} />
                </div>
                <div className={sharedStyles.right}>
                  <NavLink pageTitle="Puntos de venta" to="/puntos_de_venta/"/>
                  <NavLink pageTitle="Tienda" to="/libros/"/>
                  <NavLink pageTitle="Contacto" to="/contacto/" />
                  <NavLink pageTitle="Servicios Editoriales" to="/servicios_editoriales/" />
                </div>
                {/* Mobile nav */}
                <div className={sharedStyles.mobileNavContainer}>
                  <div className={`${sharedStyles.mobileNav}
                  ${showMobileNav ? "" : sharedStyles.mobileNavHidden}`}>
                    <NavLink pageTitle="Home" to="/"/>
                    <NavLink pageTitle="Libros" to="/libros/"/>
                    <NavLink pageTitle="Autores" to="/autores/"/>
                    <NavLink pageTitle="Puntos de venta" to="/puntos_de_venta/" />
                    <NavLink pageTitle="Noticias" to="/noticias/" />
                    <NavLink pageTitle="Contacto" to="/contacto/"/>
                    <NavLink pageTitle="Servicios Editoriales" to="/servicios_editoriales/" />
                    <NavLink pageTitle="Tienda" to="/libros/"/>
                    <NavLink pageTitle="Recepción de manuscritos" to="/recepcion_de_manuscritos/"/>
                  </div>
                  <div className={sharedStyles.mobileNavElements}>
                    <div>
                  <span onClick={this.toggleMenu}>
                    <BarsIcon onClick={this.toggleMenu} /></span>
                    </div>
                    <div>
                      <ShopIcon to="https://www.amazon.com.mx/s?me=ANEGIAL8ER9JK&marketplaceID=A1AM78C64UM0Y8"/>
                      <BookIcon to={"/libros/"} />
                    </div>
                  </div>
                </div>
              </React.Fragment>
            }
          </div>
        </div>
      </header>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: "Dharma Books + Publishing",
}

export default Header
